import { ComparisonSlider } from 'react-comparison-slider';

import downloadIcon from '../../assets/images/icons/download.svg';

export const BeforeAfterSlider = ({ whitebox, resultImage }) => {
    return (
        <div className='before-after-slider'>
            <ComparisonSlider
                defaultValue={50}
                itemOne={<img src={whitebox} alt="Image" title='Image' />}
                itemTwo={<img src={resultImage} alt="Image" title='Image' />}
                aspectRatio={1290 / 1080}
                handleBefore={
                    <div className="before-after-slider__handler-line"></div>
                }
                handleAfter={
                    <div className="before-after-slider__handler-line"></div>
                }
                handle={({ isFocused }) => {
                    return (
                        <div className='before-after-slider__handler'></div>
                    );
                }}
            />
            <a href={resultImage} download='rendered-image' className='before-after-slider__download'>
                <img src={downloadIcon} alt="Download" title='Download' />
            </a>
        </div>
    )
}