import classNames from 'classnames';
import { useImageSize } from 'react-image-size';
import { useEffect, useState } from 'react';

export const ApartmentLayout = ({ view, setView }) => {
    const [views, setViews] = useState(null);

    const urlParams = new URLSearchParams(window.location.search);
    const flatEndpointUrl = urlParams.get('flat_endpoint');
    const apartmentLayout = `https://ai-render.s3.eu-central-1.amazonaws.com/${flatEndpointUrl}/apartment-layout.png`;
    const DOMAIN = "https://ai-render.s3.eu-central-1.amazonaws.com/";

    const [dimensions] = useImageSize(apartmentLayout);

    const handleViewClick = (e) => {
        const { target } = e;
        const view = views?.find(viewItem => viewItem.id === target.dataset.view);

        if (view) setView(view);
    }

    const getViewStyles = (view) => {

        return {
            top: view.top / dimensions?.height * 100 + '%',
            left: view.left / dimensions?.width * 100 + '%',
            width: view.width / dimensions?.width * 100 + '%',
            aspectRatio: view.width / view.height,
        };
    }

    const getViews = async () => {
        try {
            const response = await fetch(`${DOMAIN + flatEndpointUrl}/views.json`);
            const data = await response.json();

            setViews(data);
        } catch (error) {
            console.log('Error', error);
        }
    }

    useEffect(() => {
        getViews();
    }, [])

    return (
        <div className="apartment-layout">
            <div style={{ aspectRatio: `${dimensions?.width}/${dimensions?.height}` }} className="apartment-layout__image">
                <img width={dimensions?.width} height={dimensions?.height} src={apartmentLayout} alt="Apartment layout" title='Apartment layout' />
            </div>
            {
                views && dimensions && (
                    views.map((viewItem, i) => (
                        <button data-room={viewItem.name} key={i} style={getViewStyles(viewItem)} dangerouslySetInnerHTML={{ __html: viewItem.image }} onClick={handleViewClick} data-view={viewItem.id} type='button' className={classNames('apartment-layout__view', { "is-active": viewItem.id === view?.id })}>
                        </button>
                    ))
                )
            }
        </div>
    )
}