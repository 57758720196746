export const AiResults = ({ aiResults, setResultImage }) => {
    const handleResultClick = (e) => {
        const { target } = e;
        const targetImage = target.dataset.image;

        setResultImage(targetImage);
    }

    return (
        <div className='ai-results'>
            <ul className='ai-results__list'>
                {aiResults.map((result, i) => (
                    <li key={i} data-image={result} onClick={handleResultClick} className='ai-results__item'>
                        <img src={result} alt="Furniture" title='Furniture' />
                    </li>
                ))}
            </ul>
        </div>
    )
}