import minimalismImage from '../assets/images/styles/minimalism.jpg';
import loftImage from '../assets/images/styles/loft.jpg';
import scandinavianImage from '../assets/images/styles/scandinavian.jpg';
import neoclassicalImage from '../assets/images/styles/neoclassical.jpg';
import modernImage from '../assets/images/styles/modern.jpg';
import luxuryImage from '../assets/images/styles/luxury.jpg';
import artnuveoImage from '../assets/images/styles/artnuveo.jpg';
import rusticImage from '../assets/images/styles/rustic.jpg';

export const FURNITURE_STYLES = [
    {
        name: "Minimalism",
        type: "minimalist",
        isAvailable: true,
        image: minimalismImage
    },
    {
        name: "Loft",
        type: "loft",
        isAvailable: true,
        image: loftImage
    },
    {
        name: "Scandinavian",
        type: "scandinavian",
        isAvailable: true,
        image: scandinavianImage
    },
    {
        name: "Neoclassical",
        type: "classical",
        isAvailable: true,
        image: neoclassicalImage
    },
    {
        name: "Modern",
        type: "modern",
        isAvailable: false,
        image: modernImage
    },
    {
        name: "Luxury",
        type: "luxury",
        isAvailable: false,
        image: luxuryImage
    },
    {
        name: "Art Nuveo",
        type: "boho",
        isAvailable: false,
        image: artnuveoImage
    },
    {
        name: "Rustic",
        type: "transitional",
        isAvailable: false,
        image: rusticImage
    },
];
